<template>
  <v-card color="#282F5B" style="border-radius:20px">
    <v-row no-gutters>
      <v-col cols="12" :lg="8" class="mx-auto">
        <v-row no-gutters v-if="isLoggedIn">
          <v-col cols="12" :lg="5" :class="['mx-auto',$vuetify.breakpoint.mdAndUp? 'py-5' : 'px-4 pt-4']">
            <v-row no-gutters>
              <v-col cols="4" class="py-0">
                <v-subheader class="font-weight-bold">{{ $t(`field.username`) }}</v-subheader>
              </v-col>
              <v-col cols="8" class="py-0">
                <v-text-field
                    class="trans_wallet_input"
                    solo
                    label="Username"
                    color="white"
                    readonly
                    rounded
                    v-model.trim="playerObj.username"
                    append-icon="mdi-content-copy"
                    @click:append="copyContent(playerObj.username)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" :lg="5" :class="['mx-auto',$vuetify.breakpoint.mdAndUp? 'py-5' : 'px-4']">
            <v-row no-gutters>
              <v-col cols="4" class="py-0">
                <v-subheader class="font-weight-bold">{{ $t(`field.password`) }}</v-subheader>
              </v-col>
              <v-col cols="8" class="py-0">
                <v-text-field
                    class="trans_wallet_input"
                    label="Password"
                    solo
                    color="white"
                    readonly
                    rounded
                    v-model.trim="playerObj.password"
                    append-icon="mdi-content-copy"
                    @click:append="copyContent(playerObj.password)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2" v-if="playerObj.shouldRegister">
            <v-btn class="play_now_button font-weight-bold mt-1" elevation="5">{{ $t(`label.register`) }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="10" class="mx-auto py-0">
        <p class="text-uppercase title_color--text title">
          {{ $t(`label.howtoplay`) }} {{ provider }}
        </p>
        <v-list color="transparent">
          <v-list-item v-for="(n,k) in howToPlay">
            <span>{{ n.id }}. {{ stringFormat(n.text, provider) }}</span>
          </v-list-item>
        </v-list>
        <p v-if="provider.toLowerCase() == 'kiss918' || provider.toLowerCase() == 'kisskaya'">
          <v-col cols="12" class="py-0">
            {{ $t(`label.gotapps`) }}
          </v-col>
          <v-col cols="12">
            <v-btn @click="openPlayNowLink" class="text-decoration-none play_now_button px-4 py-2"
                   elevation="3">
              {{ $t('label.playNow') }}
            </v-btn>
          </v-col>
        </p>
        <p class="py-0">
          <v-col cols="12" class="py-0">
            {{ $t(`label.noapps`) }}
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" :lg="3">
                <v-btn @click="downloadAndroidLink" elevation="4" ripple
                       class="text-decoration-none play_now_button px-4 py-2 font-weight-bold"
                >
                  {{ $t(`label.downloadandroid`) }}
                  <v-icon right>mdi-android</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" :lg="3">
                <v-btn @click="downloadiosLink" elevation="4" ripple
                       class="text-decoration-none ios_button primary px-4 py-2 font-weight-bold">
                  {{ $t(`label.downloadios`) }}
                  <v-icon right>mdi-apple</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </p>
      </v-col>
    </v-row>
  </v-card>

</template>

<script>
import format from "string-format";
import {locale} from "@/util/localization-helper";
import {GAME_TRANSFER_DETAILS} from "@/store/game.module";
import {GAME_REGISTER_LOCAL_PROVIDER} from "@/store/game.module";
import {GAME_RESET_TRANSFER_DETAILS} from "@/store/game.module";
import {ROUTE_NAME} from "@/constants/route.constants";
import {GAME_RESET_REGISTER_LOCAL_PROVIDER} from "@/store/game.module";
import {GAME_KISSKAYA_DEEPLINK} from "@/store/game.module";
import {uiHelper} from "../../util";

export default {
  name: "TransferMemberWalletDetails",
  props: {
    provider: {
      type: String,
      default: ''
    }
  },
  methods: {
    copyContent(content) {
      this.$copyText(content);
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.copyContent`)
      dialog.message.push(locale.getMessage(`message.copyContentSuccessfully`))

      dialog.button.push({
        title: locale.getMessage(`label.close`),
        action: () => this.closePageDialog()
      })
      this.openPageDialog(dialog)
    },
    registerProvider(provider) {
      let obj = {
        provider: provider
      }
      this.closePageDialog();
      this.$store.dispatch(`${GAME_REGISTER_LOCAL_PROVIDER}`, {obj})
    },
    openPlayNowLink() {
      if (!this.isLoggedIn) {
        let obj = {
          isTransfer: false
        }
        this.$eventHub.$emit('open-login-dialog', obj)
      } else {
        window.open(this.playerObj.quickGameUrl, '_blank')
      }
    },
    downloadAndroidLink() {
      let provider = this.provider;
      let link = _.filter(this.downloadLink, function (obj) {
        return obj.name == provider.toLowerCase()
      });

      if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/is) || navigator.userAgent.match(/iPod/i)) {
        window.location.href = _.first(link).apk;
      } else {
        window.open(_.first(link).apk, "_blank");
      }
    },
    downloadiosLink() {
      let provider = this.provider;
      let link = _.filter(this.downloadLink, function (obj) {
        return obj.name == provider.toLowerCase()
      });
      window.open(_.first(link).ios, "_blank");
    },
    getVendorDetails(provider) {
      let obj = {
        provider: provider
      }
      this.$store.dispatch(`${GAME_TRANSFER_DETAILS}`, {obj})
      if (this.provider.toLowerCase() == "kisskaya") {
        this.DeepLinkLogin()
      }
    },
    navigateToMainSlotPage() {
      this.closePageDialog();
      this.$router.push({
        name: ROUTE_NAME.SLOT
      })
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    InitializeLocalProviderRegister(provider) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = provider.toUpperCase() + " " + locale.getMessage(`label.quickregister`)
      dialog.message.push(locale.getMessage(`message.localRegisterMsg`, [provider.toUpperCase()]))

      dialog.button.push({
        title: locale.getMessage(`label.ok`),
        action: () => this.registerProvider(provider)
      })

      this.openPageDialog(dialog)
    },
    PromptRegisterTemporaryNotAvailable(provider) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = provider.toUpperCase() + " " + locale.getMessage(`label.quickregister`)
      dialog.message.push(locale.getMessage(`message.failedRegisterLocal`, [provider.toUpperCase()]))

      dialog.button.push({
        title: locale.getMessage(`label.ok`),
        action: () => this.navigateToMainSlotPage()
      })

      this.openPageDialog(dialog)
    },
    DeepLinkLogin() {
      let obj = {
        provider: this.provider
      }
      this.$store.dispatch(`${GAME_KISSKAYA_DEEPLINK}`, {obj})
    }
  },
  computed: {
    kisskayaLaunchUrl() {
      return this.$store.state.game.deeplink.launchUrl;
    },
    providerName() {
      return this.$route.params.provider
    },
    playerDetails() {
      return this.$store.state.game.playerDetails;
    },
    registeredPlayerDetails() {
      return this.$store.state.game.registeredPlayerDetails;
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
  },
  watch: {
    kisskayaLaunchUrl() {
      this.playerObj.quickGameUrl = this.kisskayaLaunchUrl;
      return this.$store.state.game.deeplink.launchUrl;
    },
    providerName() {
      this.getVendorDetails(this.provider);
      return this.$route.params.provider
    },
    registeredPlayerDetails() {
      if (this.isLoggedIn) {
        if (this.$store.state.game.registeredPlayerDetails.complete) {
          if (this.$store.state.game.registeredPlayerDetails.success) {
            let dialog = this.initializePageDialogProperty()
            dialog.title = "Register Successfully"
            dialog.message.push(locale.getMessage(`message.localRegisterSuccess`, [this.provider.toUpperCase()]))

            dialog.button.push({
              title: locale.getMessage(`label.ok`),
              action: () => this.closePageDialog()
            })
            let d = this.$store.state.game.registeredPlayerDetails;
            this.playerObj.username = d.username;
            this.playerObj.password = d.password;
            this.openPageDialog(dialog)
            this.$store.dispatch(`${GAME_RESET_REGISTER_LOCAL_PROVIDER}`);
          } else {
            this.PromptRegisterTemporaryNotAvailable(this.provider.toLowerCase());
          }
        }
      }
    },
    playerDetails() {
      if (this.$store.state.game.playerDetails.complete) {
        if (this.$store.state.game.playerDetails.success) {
          let d = this.$store.state.game.playerDetails;
          this.playerObj.username = d.username;
          this.playerObj.password = d.password;
          this.playerObj.shouldRegister = false;
          if (this.provider.toLowerCase() == 'kiss918') {
            this.playerObj.quickGameUrl = format('lobbykissandroid://lobbykissandroid?account={0}&password={1}', d.username, d.password);
          }
          this.$store.dispatch(`${GAME_RESET_TRANSFER_DETAILS}`);
        } else {
          if (this.isLoggedIn) {
            this.InitializeLocalProviderRegister(this.provider.toLowerCase());
          } else {
            let obj = {
              isTransfer: true
            }
            //this.$eventHub.$emit('open-login-dialog', obj)
          }
        }
      }
    }
  },
  data: () => ({
    isRegister: false,
    downloadLink: [
      {
        "name": "kiss918",
        "apk": "https://a1.d.918kiss.com/",
        "ios": "https://a1.d.918kiss.com/"
      },
      {
        "name": "pussy888",
        "apk": "https://s3-ap-southeast-1.amazonaws.com/pussy888/pussy888/appsetup/apk/pussy888.apk",
        "ios": "itms-services://?action=download-manifest&url=https://pussy888.s3.amazonaws.com/pussy888/appsetup/ios/pussy888.plist"
      },
      {
        "name": "mega888",
        "apk": "https://m3.mgwin883.com/mega/index.html",
        "ios": "https://m3.mgwin883.com/mega/index.html"
      },
      {
        "name": "xe88",
        "apk": "https://d.playalotgame.com/",
        "ios": "https://d.playalotgame.com/"
      },
      {
        "name": "kisskaya",
        "apk": "https://appdownload.jra3333.com",
        "ios": "https://appdownload.jra3333.com"
      },
    ],
    stringFormat: format,
    playerObj: {
      username: '',
      password: '',
      shouldRegister: false,
      quickGameUrl: '',
    },
    howToPlay: [
      {
        id: 1,
        text: locale.getMessage('message.transfer_one')
      },
      {
        id: 2,
        text: locale.getMessage('message.transferDownload')
      },
      {
        id: 3,
        text: locale.getMessage('message.launchApp')
      },
    ]
  }),
  mounted() {
    if (this.isLoggedIn) {
      this.getVendorDetails(this.provider);
    }
  }
}
</script>

<style lang="scss">
.trans_wallet_input .v-icon.v-icon {
  color: grey !important;
}

.play_now_button {
  border-radius: 25px;
  background: transparent linear-gradient(180deg, #FFA15C 0%, #E2D200 100%) 0% 0% no-repeat padding-box;
}

.ios_button {
  border-radius: 25px;
}

.theme--dark.trans_wallet_input.v-text-field--solo > .v-input__control > .v-input__slot {
  background: white !important;
}

.theme--dark.trans_wallet_input.v-input--is-readonly input, .theme--dark.v-input--is-readonly textarea, .trans_wallet_input .v-label--is-readonly {
  color: #848484 !important;
}
</style>