<template>
  <v-row no-gutters>
    <v-col cols="11" :lg="10" class="mx-auto py-0">
      <v-img :src="`${mediaUrl}/transfer_banners/${provider}.jpg`" :aspect-ratio="2328/792" width="1500"
             class="mx-auto" style="border-radius:25px;"></v-img>
      <v-row no-gutters class="mt-4">
        <v-col
            v-if="$vuetify.breakpoint.mdAndDown && (provider.toLowerCase() == 'kiss918' || provider.toLowerCase() == 'kisskaya')"
            cols="12" class="my-0 py-0 mx-auto px-3">
          <v-card color="primary" style="border-radius:15px;">
            <v-card-title class="py-0">
              <v-row>
                <v-col cols="6" class="d-flex justify-center align-center">
                  {{ $t(`label.haveApps`) }}
                </v-col>
                <v-spacer>
                </v-spacer>
                <v-col cols="6" class="subtitle-1 d-flex justify-center align-center">
                  <v-btn @click="openPlayNowLink" elevation="10"
                         class="pa-2 play_now_button text-uppercase font-weight-bold">
                    {{ $t(`label.jomPlay`) }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col v-if="isLoggedIn" cols="12" :lg="3"
               :class="['my-0 py-0 px-3', $vuetify.breakpoint.mdAndDown?'my-5':'my-0']">
          <app-transfer-component :isNonBanking="true" :provider="provider"></app-transfer-component>
        </v-col>
        <v-col cols="12" :lg="isLoggedIn? 9 : 12" class="my-0 py-0 mx-auto px-3">
          <app-transfer-member-wallet-details :provider="provider"></app-transfer-member-wallet-details>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {SHARED} from "@/constants/constants";
import AppTransferComponent from "@/views/bank/transfer";
import AppTransferMemberWalletDetails from "@/components/member/TransferMemberWalletDetails"
import format from "string-format";

export default {
  components: {
    AppTransferComponent,
    AppTransferMemberWalletDetails
  },
  props: {
    provider: {
      type: String,
      default: false,
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    kisskayaLaunchUrl() {
      return this.$store.state.game.deeplink.launchUrl;
    },
    playerDetails() {
      return this.$store.state.game.playerDetails;
    },
  },
  watch: {
    playerDetails() {
      if (this.$store.state.game.playerDetails.complete) {
        if (this.$store.state.game.playerDetails.success) {
          let d = this.$store.state.game.playerDetails;
          this.playerObj.username = d.username;
          this.playerObj.password = d.password;
          this.playerObj.shouldRegister = false;
          if (this.provider.toLowerCase() == 'kiss918') {
            if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/is) || navigator.userAgent.match(/iPod/i)) {
              this.playerObj.quickGameUrl = format('LobbyKiss64://account={0}&password={1}', d.username, d.password);
            } else {
              this.playerObj.quickGameUrl = format('lobbykissandroid://lobbykissandroid?account={0}&password={1}', d.username, d.password);
            }
          }
        }
      }
    },
    kisskayaLaunchUrl() {
      this.playerObj.quickGameUrl = this.kisskayaLaunchUrl;
      return this.$store.state.game.deeplink.launchUrl;
    },
  },
  data: () => ({
    playerObj: {
      username: '',
      password: '',
      shouldRegister: false,
      quickGameUrl: '',
    },
    mediaUrl: SHARED.MEDIA_SERVER_URL,
  }),
  methods: {
    openPlayNowLink() {
      window.open(this.playerObj.quickGameUrl, '_blank')
    },
  },
  name: "TransferWalletMainComponent"
}
</script>

<style scoped>

</style>